<template>
  <div class="hero-slider">
    <transition :name="imageTransitionName" mode="out-in" appear>
      <figure
        :key="content.title"
        v-touch:swipe.left="next"
        v-touch:swipe.right="prev"
        class="hero-slider__image"
        :style="background"
      />
    </transition>

    <transition v-if="$device.laptop" name="hero-slider__content" mode="out-in" appear>
      <LangRouterLink
        :key="content.title"
        :to="{
          name: 'product',
          params: { productTypeSlug: productParams.productTypeSlug, productSlug: productParams.productSlug }
        }"
        class="hero-slider__content"
      >
        <figure class="hero-slider__thumb">
          <img :src="content.thumb" :alt="content.title" />
        </figure>

        <div class="hero-slider__text">
          <p class="hero-slider__title">{{ content.title }}</p>
          <p>{{ content.text }}</p>
        </div>
      </LangRouterLink>
    </transition>
    <LangRouterLink
      v-else
      :key="content.title"
      :to="{
        name: 'product',
        params: { productTypeSlug: productParams.productTypeSlug, productSlug: productParams.productSlug }
      }"
      class="hero-slider__show"
    >
      <Icon id="show" class="icon-show" />
    </LangRouterLink>
    <template v-if="slides.length > 1">
      <menu class="hero-slider__nav">
        <button
          v-for="(n, index) in slides.length"
          :key="n"
          :class="['hero-slider__dot', { '-active': currentIndex === index }]"
          @click="goToIndex(index)"
        ></button>
      </menu>

      <menu class="hero-slider__menu">
        <button class="hero-slider__button" @click="prev()">
          <Icon id="back" class="icon-back" />
        </button>

        <button class="hero-slider__button" @click="next()">
          <Icon id="next" class="icon-next" />
        </button>
      </menu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HeroSlider',
  props: {
    slides: { type: Array, default: Array }
  },
  data() {
    return {
      currentIndex: 0,
      isPrev: false
    }
  },
  computed: {
    currentSlide() {
      return this.slides[this.currentIndex] || {}
    },
    content() {
      const { slideTitle: title, slideText: text, slideThumb: thumb } = this.currentSlide
      return { title, text, thumb }
    },
    background() {
      const { sliderImage: image } = this.currentSlide
      return { backgroundImage: `url('${image}')` }
    },
    productParams() {
      const [productId] = this.currentSlide.slideProduct
      const { slug: productSlug, typeId } = this.$store.state.products[productId]
      const { slug: productTypeSlug } = this.$store.getters.productTypes[typeId]
      return { productSlug, productTypeSlug }
    },
    imageTransitionName() {
      if (this.$device.laptop) return 'hero-slider__image-laptop'
      return !this.isPrev ? 'hero-slider__image-next' : 'hero-slider__image-prev'
    }
  },
  methods: {
    next() {
      this.isPrev = false
      this.currentIndex = (this.currentIndex + 1) % this.slides.length
    },
    prev() {
      this.isPrev = true
      const wrappedIndex = this.currentIndex || this.slides.length
      this.currentIndex = wrappedIndex - 1
    },
    goToIndex(index) {
      this.isPrev = index < this.currentIndex
      this.currentIndex = index
    }
  }
}
</script>
