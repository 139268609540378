function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"mobile-nav"},[_vm._l((_vm.links),function(ref){
var label = ref.label;
var rest = objectWithoutProperties( ref, ["label"] );
var link = rest;
return _c('LangRouterLink',{key:label,staticClass:"mobile-nav__link",attrs:{"to":link},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(label))])}),_c('nav',{staticClass:"mobile-nav__bottom"},[_vm._l((_vm.langSwitchLinks),function(langCode){return _c('LangSwitchLink',{key:langCode,staticClass:"mobile-nav__lang",attrs:{"lang":langCode},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(langCode.toUpperCase())+" ")])}),_c('div',[_c('a',{staticClass:"navbar__icon",attrs:{"href":_vm.socials.facebook,"target":"_blank","rel":"noopener"}},[_c('Icon',{attrs:{"id":"facebook"}})],1),_c('a',{staticClass:"navbar__icon",attrs:{"href":_vm.socials.linkedIn,"target":"_blank","rel":"noopener"}},[_c('Icon',{attrs:{"id":"linkedin"}})],1),_c('a',{staticClass:"navbar__icon",attrs:{"href":_vm.socials.youtube,"target":"_blank","rel":"noopener"}},[_c('Icon',{attrs:{"id":"youtube"}})],1)])],2),_c('nav',{staticClass:"mobile-nav__legal"},_vm._l((_vm.$store.state.content.links.legal),function(ref,key){
var label = ref.label;
var route = ref.route;
return _c('LangRouterLink',{key:key,staticClass:"mobile-nav__legal__item",attrs:{"to":route},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(label[_vm.$lang.current]))])}),1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }