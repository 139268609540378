export const legal = {
  privacy: { label: { en: 'Privacy & Cookies', fr: 'Politique de confidentialité' }, route: { name: 'privacy' } },
  terms: { label: { en: 'Disclaimer', fr: 'Termes et conditions' }, route: { name: 'terms' } }
}

export const anchors = {
  about: [
    {
      label: { en: 'Our Business', fr: "L'entreprise" },
      hash: '#business'
    },
    {
      label: { en: 'Our clients', fr: 'Nos clients' },
      hash: '#clients'
    },
    {
      label: { en: 'Partnerships', fr: 'Partenariats' },
      hash: '#partnerships'
    },
    {
      label: { en: 'Careers', fr: 'Carrières' },
      hash: '#careers'
    }
  ],
  product: [
    {
      label: { en: 'Overview', fr: 'Présentation' },
      hash: '#overview'
    },
    {
      label: { en: 'Assets', fr: 'Avantages' },
      hash: '#assets'
    },
    {
      label: { en: 'Specs', fr: 'Spécifications' },
      hash: '#specs'
    }
  ],
  services: [
    {
      label: { en: 'Standard Products', fr: 'Produits standards' },
      hash: '#standard'
    },
    {
      label: { en: 'Original Equipment (OEM)', fr: "Équipement d'origine (OEM)" },
      hash: '#oem'
    },
    {
      label: { en: 'Custom Products', fr: 'Produit sur mesure' },
      hash: '#custom'
    },
    {
      label: { en: 'Consultations and feasibility studies', fr: 'Consultation et études de faisabilité' },
      hash: '#consultation'
    }
  ],
  markets: [
    {
      label: { en: 'Energy and Nuclear', fr: 'Énergie et nucléaire' },
      hash: '#energy'
    },
    {
      label: { en: 'Aeronautical', fr: 'Aéronautique' },
      hash: '#aeronautical'
    },
    {
      label: { en: 'Indutrial Manufacturing', fr: 'Fabrication industrielle' },
      hash: '#industrial'
    },
    {
      label: {
        en: 'Universities, research centers and laboratories',
        fr: 'Universités, centres de recherche et laboratoires'
      },
      hash: '#research'
    },
    {
      label: { en: 'Oil and gaz', fr: 'Pétrole et gaz' },
      hash: '#oil-gaz'
    }
  ]
}
