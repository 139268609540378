<template>
  <div class="page-product">
    <header class="header">
      <div class="header__content">
        <figure class="header__background -product">
          <!-- <img :src="$wp.header.image" class="header__background-image" /> -->
        </figure>

        <div class="header__column">
          <p class="header__legend">{{ productType.name }}</p>
          <h1 class="header__title">{{ product.title }}</h1>
          <p class="header__intro">{{ product.infos.intro }}</p>

          <nav class="header__nav">
            <LangRouterLink v-for="item in anchors" :key="item.id" :to="getHashLink(item)" class="header__anchor">{{
              item.label
            }}</LangRouterLink>
          </nav>
        </div>
      </div>

      <MediaSlider :slides="product.slider.items" />
    </header>

    <section id="overview" class="section">
      <h2 class="section__legend">{{ $wp.presentation.title }}</h2>
      <h3 class="section__title -narrow">{{ product.presentation.legend }}</h3>
      <RawHtml class="section__content" :html="product.presentation.richText" />
    </section>

    <section id="assets" class="section -center">
      <h2 class="section__legend">{{ $wp.benefits.title }}</h2>
      <p class="section__title">{{ product.benefits.legend }}</p>

      <div v-if="product.benefits.image" class="section__content row">
        <figure
          class="section__column section__image -contain"
          :style="{ backgroundImage: `url('${product.benefits.image}')` }"
        />

        <RawHtml :html="product.benefits.richText" class="section__column list-table" align-left />
      </div>

      <RawHtml v-else :html="product.benefits.richText" class="list-table -thirds" align-left />
    </section>

    <section v-if="product.specs.items" id="specs" class="section">
      <div class="section__content -pad">
        <h2 class="section__legend">{{ $wp.specs.title }}</h2>
        <p class="section__title">{{ product.specs.legend }}</p>

        <div class="row -wrap table">
          <div v-for="{ id, title, rows } in product.specs.items" :key="id" class="section__column">
            <h4 class="table__heading">{{ title }}</h4>
            <div v-for="row in rows" :key="row.id" class="table__row">
              <p class="table__cell -label">{{ row.label }}</p>
              <RawHtml :html="row.richText" class="table__cell" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <CtaContact :title="$wp.contact.title" :subtitle="$wp.contact.subtitle" :link-label="$wp.contact.linkLabel" />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CtaContact } from '@/components/partials'
import { MediaSlider } from '@/components/sliders'

export default {
  name: 'Product',
  components: { CtaContact, MediaSlider },
  mixins: [PageMetaMixin],
  props: {
    productSlug: { type: String, default: null },
    productTypeSlug: { type: String, default: null }
  },
  contentKey: 'product',
  computed: {
    product() {
      return this.$store.getters.productsBySlug[this.productSlug]
    },
    anchors() {
      return this.$store.getters.anchors('product')
    },
    productType() {
      return this.$store.getters.productTypesBySlug[this.productTypeSlug]
    }
  },
  methods: {
    getHashLink({ hash }) {
      return { ...this.$route, hash }
    }
  }
}
</script>
