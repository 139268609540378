import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { anchors, legal } from './links'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isReady: false,
    pageMap: {},
    productTypes: {},
    services: {},
    products: {},
    markets: {},
    values: {},
    content: {
      about: {},
      contact: {},
      home: {},
      markets: {},
      product: {},
      products: {},
      services: {},
      settings: {},
      links: { anchors, legal }
    }
  },
  actions,
  getters,
  mutations
})
