export default {
  currentLang: ({ route }) => {
    const { meta = {} } = route
    return meta.lang || ''
  },
  productTypes: ({ productTypes }, { currentLang }) => {
    return Object.values(productTypes).reduce((typeMap, type) => {
      const {
        excerpt,
        excerpt_en,
        id,
        intro,
        intro_en,
        legend,
        legend_en,
        linkLabel,
        linkLabel_en,
        medias,
        name,
        name_en,
        slug
      } = type

      return currentLang === 'fr'
        ? { ...typeMap, [id]: { slug, id, name, intro, legend, excerpt, linkLabel, medias } }
        : {
            ...typeMap,
            [id]: {
              slug,
              id,
              name: name_en,
              intro: intro_en,
              legend: legend_en,
              excerpt: excerpt_en,
              linkLabel: linkLabel_en,
              medias
            }
          }
    }, {})
  },
  productTypesBySlug: (state, { productTypes }) => {
    return Object.values(productTypes).reduce((slugMap, type) => {
      return { ...slugMap, [type.slug]: type }
    }, {})
  },
  productsBySlug: ({ products }) => {
    return Object.values(products).reduce((slugMap, type) => {
      return { ...slugMap, [type.slug]: type }
    }, {})
  },
  anchors: ({ content }, { currentLang }) => {
    return slug => {
      const { anchors } = content.links
      return !anchors[slug]
        ? []
        : anchors[slug].map(({ label, ...anchor }) => {
            return { label: label[currentLang], ...anchor }
          })
    }
  }
}
