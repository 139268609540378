<template>
  <section class="section -center">
    <h2 v-scroll:reveal class="section__title">{{ title }}</h2>
    <p v-scroll:reveal="{ delay: 75 }" class="section__subtitle">{{ subtitle }}</p>
    <LangRouterLink v-scroll:reveal="{ delay: 150 }" :to="{ name: 'contact' }" class="button -link -cta">{{
      linkLabel
    }}</LangRouterLink>
  </section>
</template>

<script>
export default {
  name: 'CtaContact',
  props: {
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    linkLabel: { type: String, default: null }
  }
}
</script>
