import scroll from 'scroll'

let ScrollUtilsVM = null

const { body } = document
const { requestAnimationFrame } = window
const defaultScrollingElement = document.scrollingElement || document.documentElement

export default {
  get global() {
    return ScrollUtilsVM
  },
  install(Vue, { scrollingElement = defaultScrollingElement, scrollEventTarget }) {
    ScrollUtilsVM = new Vue({
      name: 'ScrollVM',
      data() {
        return {
          el: scrollingElement,
          position: 0,
          storedPosition: null,
          mute: true
        }
      },
      created() {
        const scrollEventElement = scrollEventTarget || this.el
        let isQueued = true

        const fireScrollEvent = () => {
          if (!this.mute) this.$emit('scroll')
          if (!this.freeze) this.position = this.el.scrollTop
          isQueued = false
        }

        const queueScrollEvent = () => {
          if (isQueued) return
          requestAnimationFrame(fireScrollEvent)
          isQueued = true
        }

        fireScrollEvent()
        this.mute = false
        scrollEventElement.addEventListener('scroll', queueScrollEvent)
      },
      methods: {
        prevent() {
          this.storedPosition = this.position
          this.mute = true
          body.style.position = 'fixed'
          body.style.top = `-${this.storedPosition}px`

          return this.restore
        },
        restore() {
          if (!this.mute) return

          const position = this.storedPosition || 0
          this.storedPosition = null
          this.mute = false
          body.style.position = ''
          body.style.top = ''
          this.to(position)
        },
        on(handler, immediate) {
          if (immediate) handler()
          this.$on('scroll', handler)
        },
        off(handler) {
          this.$off('scroll', handler)
        },
        to(targetPosition = 0, offset = 0, duration) {
          if (duration) scroll.top(this.el, targetPosition - offset, { duration })
          else this.el.scrollTop = targetPosition - offset
        },
        toTop(offset, duration) {
          this.to(0, offset, duration)
        },
        toBottom(offset, duration) {
          const { scrollHeight = 0 } = this.el
          this.to(scrollHeight, offset, duration)
        },
        toTarget(target, offset, duration) {
          const targetElement = typeof target === 'string' ? this.el.querySelector(target) : target
          if (!targetElement) return

          const { scrollTop } = this.el
          const targetTop = targetElement.getBoundingClientRect().top
          this.to(scrollTop + targetTop, offset, duration)
        }
      }
    })

    Vue.prototype.$scroll = ScrollUtilsVM
  }
}
