<template>
  <section id="clients" ref="section" class="section testimonial-slider">
    <div class="section__content -pad">
      <h2 v-scroll:reveal class="section__legend">{{ title }}</h2>
      <h3 v-scroll:reveal="{ delay: 75 }" class="section__title">{{ legend }}</h3>

      <template v-if="isRefReady">
        <div class="testimonial-slider__content">
          <div v-scroll:reveal="{ delay: 100 }" class="testimonial-slider__left">
            <transition name="testimonial-slider__image" mode="out-in">
              <figure :key="image" class="testimonial-slider__image" :style="{ backgroundImage: `url('${image}')` }" />
            </transition>

            <menu class="testimonial-slider__menu">
              <button :class="getButtonClassName()" @click="$emit('index-change', currentIndex + 1)">
                <Icon id="next" />
              </button>

              <button :class="getButtonClassName(true)" @click="$emit('index-change', currentIndex - 1)">
                <Icon id="back" />
              </button>
            </menu>
          </div>
          <transition name="testimonial-slider__right" mode="out-in">
            <div :key="name" class="testimonial-slider__right">
              <h3 :key="name" class="section__subtitle">{{ name }}</h3>

              <blockquote :key="quote.content" class="testimonial-slider__quote">
                <p class="testimonial-slider__quote-text">
                  {{ quote.content }}<span class="testimonial-slider__quote-close">{{ quote.lastChar }}</span>
                </p>
              </blockquote>

              <h3 :key="author" class="testimonial-slider__author">{{ author }}</h3>
            </div>
          </transition>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TestimonialSlider',
  props: {
    image: { type: [String, Boolean], default: false },
    title: { type: String, default: null },
    name: { type: String, default: null },
    legend: { type: String, default: null },
    text: { type: String, default: null },
    author: { type: String, default: null },
    currentIndex: { type: Number, default: 0 },
    slidesCount: { type: Number, default: 0 }
  },
  data() {
    return {
      isRefReady: false,
      timeout: null
    }
  },
  computed: {
    activeNodeStyle() {
      return { transform: `translate3d(${this.currentIndex * 2.25}rem, 0, 0)` }
    },
    quote() {
      return {
        content: this.text.replace(/.$/, ''),
        lastChar: this.text.charAt(this.text.length - 1)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isRefReady = true
      if (!this.$device.tablet) this.queueNextAutoSlide()
    })
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    getButtonClassName(isPrev) {
      return [
        'testimonial-slider__button',
        {
          '-prev': isPrev,
          '-disabled': isPrev ? !this.currentIndex : this.currentIndex >= this.slidesCount - 1
        }
      ]
    },
    queueNextAutoSlide() {
      this.timeout = setTimeout(this.autoSlide, 10000)
    },
    autoSlide() {
      this.$emit('index-change', (this.currentIndex + 1) % this.slidesCount)
      if (!this.$device.tablet) this.queueNextAutoSlide()
    }
  }
}
</script>
