<template>
  <transition appear name="cookie-transition" appear-active-class="cookie-transition-appear" mode="out-in">
    <section v-if="isCookieBarVisible" class="cookie-bar">
      <div class="cookie-bar__content">
        <p class="cookie-bar__text">{{ $lang.current === 'fr' ? cookieBarTextFr : cookieBarTextEn }}</p>
        <button class="button -primary" @click="acceptCookies">
          <span>{{ $lang.current === 'fr' ? 'Accepter' : 'Accept' }}</span>
        </button>
        <LangRouterLink :to="{ name: 'privacy' }" class="button -link cookie-bar__link">{{
          $lang.current === 'fr' ? 'Politique de confidentialité' : 'Privacy Policy'
        }}</LangRouterLink>
        <button class="cookie-bar__close" @click="closeCookieBar">
          <Icon id="close" />
        </button>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: 'CookieBar',
  data() {
    return {
      isCookieBarVisible: !localStorage.getItem('sgndt-cookie-timestamp'),
      cookieBarTextFr:
        'Nous utilisons des témoins pour garantir la meilleure expérience sur notre site Web. Si vous continuez à l’utiliser, nous supposerons que vous êtes satisfait.',
      cookieBarTextEn:
        'We use cookies to ensure an optimal experience on our website. If you continue to use it, we will assume that you are satisfied.'
    }
  },
  methods: {
    acceptCookies() {
      this.setCookieTimestamp()
      this.isCookieBarVisible = false
    },
    closeCookieBar() {
      this.isCookieBarVisible = false
    },
    setCookieTimestamp() {
      localStorage.setItem('sgndt-cookie-timestamp', Date.now())
    }
  }
}
</script>
