<template>
  <div v-if="productType" class="page-products">
    <header class="header -center">
      <div class="header__content">
        <nav class="header__filters">
          <LangRouterLink
            v-for="({ name, slug }, i) in productTypeLinks"
            :key="name"
            v-scroll:reveal="{ delay: 500 + i * 50 }"
            :to="{ ...$route, params: { productTypeSlug: slug } }"
            class="header__link -filter"
          >
            {{ name }}
          </LangRouterLink>
        </nav>

        <p :key="productType.legend" v-scroll:reveal="{ delay: 600 }" class="header__title">{{ productType.legend }}</p>
        <p :key="productType.intro" v-scroll:reveal="{ delay: 650 }" class="header__intro">{{ productType.intro }}</p>
      </div>
    </header>

    <section class="grid-list -section">
      <ProductCard
        v-for="({ id, title, slug, image, text }, i) in products"
        :key="id"
        v-scroll:reveal="{ delay: 700 + i * 50, offset: 0 }"
        :image-url="image"
        class="grid-list__item"
      >
        <h2 class="card__title">{{ title }}</h2>

        <p>{{ text }}</p>
        <LangRouterLink
          :to="{ name: 'product', params: { productTypeSlug, productSlug: slug } }"
          class="section__link"
          :aria-labelledby="'Apprenez-en plus sur ' + title"
          >{{ $lang.current === 'fr' ? 'Apprenez-en plus' : 'Learn more' }}</LangRouterLink
        >
      </ProductCard>
    </section>

    <section v-if="$device.laptop" class="section row">
      <div class="section__content -split row__item -span-6 -skip-1">
        <h2 v-scroll:reveal class="section__title -full">{{ $wp.services.title }}</h2>
        <p v-scroll:reveal="{ delay: 75 }" class="section__subtitle">{{ $wp.services.subtitle }}</p>
        <p v-scroll:reveal="{ delay: 150 }">{{ service.excerpt }}</p>

        <LangRouterLink
          v-scroll:reveal="{ delay: 200 }"
          :to="{ name: 'services', hash: `#${service.slug}` }"
          class="section__link"
          >{{ $wp.services.linkLabel }}</LangRouterLink
        >
      </div>

      <div class="row__item -skip-1 -span-3">
        <figure v-if="service.medias" class="image-card" :style="{ backgroundImage: `url('${service.medias.image}')` }">
          <figcaption class="image-card__badge disc" :style="{ backgroundImage: `url('${service.medias.badge}')` }" />
        </figure>
      </div>
    </section>

    <section v-else class="section">
      <div class="section__content">
        <h2 v-scroll:reveal class="section__title -full">{{ $wp.services.title }}</h2>

        <figure v-if="service.medias" class="image-card" :style="{ backgroundImage: `url('${service.medias.image}')` }">
          <figcaption class="image-card__badge disc" :style="{ backgroundImage: `url('${service.medias.badge}')` }" />
        </figure>

        <p v-scroll:reveal="{ delay: 75 }" class="section__subtitle -push-mobile">{{ $wp.services.subtitle }}</p>
        <p v-scroll:reveal="{ delay: 150 }">{{ service.excerpt }}</p>

        <LangRouterLink
          v-scroll:reveal="{ delay: 200 }"
          :to="{ name: 'services', hash: `#${service.slug}` }"
          class="section__link"
          >{{ $wp.services.linkLabel }}</LangRouterLink
        >
      </div>
    </section>

    <CtaContact :title="$wp.contact.title" :subtitle="$wp.contact.subtitle" :link-label="$wp.contact.linkLabel" />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { ProductCard, CtaContact } from '@/components'

export default {
  name: 'Products',
  contentKey: 'products',
  components: { ProductCard, CtaContact },
  mixins: [PageMetaMixin],
  props: {
    productTypeSlug: { type: String, default: null }
  },
  computed: {
    products() {
      const filteredProducts = Object.values(this.$store.state.products).filter(product => {
        return Number(product.typeId) === Number(this.productType.id)
      })

      return filteredProducts.map(({ id, title, infos, slider, slug }) => {
        const [{ image }] = slider.items || [{}]
        return { id, title, slug, image, text: infos.excerpt }
      })
    },
    productTypeLinks() {
      return Object.values(this.$store.getters.productTypes).map(({ name, slug }) => ({ name, slug }))
    },
    productType() {
      return this.$store.getters.productTypesBySlug[this.productTypeSlug]
    },
    service() {
      const [serviceId] = this.$wp.services.service
      return this.$store.state.services[serviceId]
    }
  },
  beforeMount() {
    if (this.productTypeSlug) return
    const [productTypeSlug] = Object.keys(this.$store.getters.productTypesBySlug)
    this.$router.replace({ ...this.$route, params: { productTypeSlug } })
  }
}
</script>
