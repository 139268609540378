const getContentKey = (key, { content }) => content[key] || {}
const spreadContentKeys = (keys, state) =>
  keys.reduce((content, key) => ({ ...content, [key]: getContentKey(key, state) }), {})

export default Vue =>
  Vue.mixin({
    beforeCreate() {
      if (!this.$options.contentKey) return
      const { contentKey, computed = {} } = this.$options
      const getContent = Array.isArray(contentKey) ? spreadContentKeys : getContentKey

      this.$options.computed = {
        ...computed,
        $wp() {
          return getContent(contentKey, this.$store.state)
        }
      }
    },

    methods: {
      $wpGetPageSlug([pageId]) {
        if (!pageId) return null
        const { slug = null } = this.$store.state.pageMap[pageId] || {}
        return slug
      },
      $wpParseLink(link = {}) {
        const { page = [], label: rawLabel } = link
        const label = rawLabel ? rawLabel.trim() : null
        const name = this.$wpGetPageSlug(page)
        return name ? { label, to: { name } } : label || null
      }
    }
  })
