import './styles'
import './startup'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import DeviceQueries from 'vue-device-queries'
import Vue2TouchEvents from 'vue2-touch-events'
import Recaptcha from '@/extensions/Recaptcha'
import { sync } from 'vuex-router-sync'
// import VueAnalytics from 'vue-analytics'

import store from './store'
import router from './router'
import App from './App.vue'
import GlobalComponents from './components/global'
import GlobalEvents from './extensions/GlobalEvents'
import ScrollUtils from './extensions/ScrollUtils'
import ScrollHub from './extensions/scrollHub'
import wpContentMixin from './extensions/wpContentMixin'
import { toRem } from './helpers'

const offset = window.innerHeight * 0.15
const scrollingElement = document.scrollingElement || document.documentElement

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(GlobalComponents)
Vue.use(GlobalEvents)
Vue.use(wpContentMixin)
Vue.use(Recaptcha)
Vue.use(ScrollUtils, { scrollEventTarget: window })
Vue.use(ScrollHub, { scrollingElement, scrollEventTarget: window, reveal: { offset } })
Vue.use(Vue2TouchEvents)
// Vue.use(VueAnalytics, { id: 'UA-178215821-1', router })

Vue.use(DeviceQueries, {
  phone: `max-width: ${toRem(567)}`,
  tablet: `min-width: ${toRem(568)}`,
  mobile: `max-width: ${toRem(1024)}`,
  laptop: `min-width: ${toRem(1025)}`,
  desktop: `min-width: ${toRem(1360)}`
})

sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
