<template>
  <footer v-if="!isContact" class="footer">
    <div class="footer__border">
      <figure class="footer__background" />

      <div class="footer__content">
        <h6 class="footer__legend">Siège social</h6>
        <address class="footer__address">{{ '425, 3e Avenue, Suite 200\nLévis, Québec, G6W 5M6' }}</address>

        <div class="footer__contact">
          <p>(418) 830-8808</p>
          <p>info@sgndt.com</p>
        </div>

        <ul class="footer__socials" />

        <nav class="footer__legal">
          <LangRouterLink
            v-for="({ label, route }, key) in $store.state.content.links.legal"
            :key="key"
            class="footer__legal__item"
            :to="route"
            >{{ label[$lang.current] }}</LangRouterLink
          >
        </nav>
      </div>

      <div v-if="$device.laptop" class="footer__bar">
        <p>&copy; 2021 | SG NDT</p>
        <p>Site réalisé par Beet</p>
      </div>
    </div>

    <div v-if="!$device.laptop" class="footer__bar">
      <p>&copy; 2021 | SG NDT</p>
      <p>Site réalisé par Beet</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  computed: {
    isContact() {
      return this.$route.name.includes('contact')
    }
  }
}
</script>
