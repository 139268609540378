<template>
  <div ref="ctaProduct" class="cta-product">
    <a href="" target="_blank" rel="noopener" aria-labelledby="Apprenez-en plus sur notre">
      <figure class="cta-product__image" />

      <div class="cta-product__text">
        <p>nom du produit</p>
        <p>extrait</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'CtaProduct',
  props: {
    id: { type: Number, default: null },
    className: { type: String, default: null }
  }
}
</script>
