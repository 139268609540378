<template>
  <div class="page-services">
    <header class="header">
      <div class="header__content">
        <figure class="header__background">
          <img v-if="$device.laptop" :src="$wp.header.image" class="header__background-image -services" />
        </figure>
        <img v-if="!$device.laptop" :src="$wp.header.image" class="header__background-image -services" />
        <div class="header__column">
          <h1 v-scroll:reveal="{ delay: 550 }" class="header__legend">{{ $wp.header.title }}</h1>
          <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $wp.header.legend }}</p>
          <p v-scroll:reveal="{ delay: 650 }" class="header__intro">{{ $wp.header.intro }}</p>

          <nav class="header__nav -compact">
            <LangRouterLink
              v-for="(item, i) in anchors"
              :key="item.id"
              v-scroll:reveal="{ delay: 700 + i * 50, offset: -100 }"
              :to="{ name: 'services', hash: item.hash }"
              class="header__anchor"
            >
              {{ item.label }}
            </LangRouterLink>
          </nav>
        </div>
      </div>
    </header>

    <section class="section">
      <div class="section__content grid-list">
        <Card
          v-for="(item, i) in services.top"
          :key="item.id"
          v-scroll:reveal="{ delay: i * 75 }"
          :badge-url="item.medias.badge"
          :image-url="item.medias.image"
          :hash="item.slug"
          class="grid-list__item"
          small-image
        >
          <h2 class="section__title">{{ item.title }}</h2>
          <RawHtml preserve-breaks :html="item.richText" />

          <LangRouterLink
            v-if="item.linkLabel != ''"
            :to="{ name: 'products', slug: item.slug }"
            class="section__link"
            >{{ item.linkLabel }}</LangRouterLink
          >
        </Card>
      </div>

      <div class="section__content -pad -push -column">
        <Card
          v-for="(item, i) in services.bottom"
          :key="item.id"
          v-scroll:reveal="{ delay: i * 75 }"
          :badge-url="item.medias.badge"
          :image-url="item.medias.image"
          :hash="item.slug"
          split
          large-image
        >
          <h2 class="section__title">{{ item.title }}</h2>
          <RawHtml preserve-breaks :html="item.richText" />

          <LangRouterLink
            v-if="item.linkLabel != ''"
            :to="{ name: 'products', slug: item.slug }"
            class="section__link"
            >{{ item.linkLabel }}</LangRouterLink
          >
        </Card>
      </div>
    </section>

    <CtaContact :title="$wp.contact.title" :subtitle="$wp.contact.subtitle" :link-label="$wp.contact.linkLabel" />
  </div>
</template>

<script>
import RawHtml from '../components/global/RawHtml.vue'
import { PageMetaMixin } from '@/mixins'
import { Card, CtaContact } from '@/components'

export default {
  name: 'Services',
  contentKey: 'services',
  components: { Card, CtaContact, RawHtml },
  mixins: [PageMetaMixin],
  computed: {
    services() {
      const [first, second, ...bottom] = Object.values(this.$store.state.services)
      return { top: [first, second], bottom }
    },
    anchors() {
      return this.$store.getters.anchors('services')
    }
  }
}
</script>
