let LanguageRouterState = {}

const LanguageRouter = {
  name: 'LangRouter',
  render: h => h('router-view')
}

const removeLeadingSlash = str => str.replace(/^\//, '')

const langMetaFactory = (lang, isRoot) => ({ name, path: inputPath, meta = {}, ...route }) => ({
  ...route,
  path: typeof inputPath === 'string' ? removeLeadingSlash(inputPath) : removeLeadingSlash(inputPath[lang]),
  name: isRoot ? name : `${name}:${lang}`,
  meta: { ...meta, lang }
})

const langRoutesFactory = (routes, isRoot) => langCode => ({
  path: isRoot ? '/' : `/${langCode}`,
  component: LanguageRouter,
  children: routes.map(langMetaFactory(langCode, isRoot))
})

export const vuexGetters = {
  currentLang: ({ route }) => {
    const { meta = {} } = route
    return meta.lang || ''
  }
}

export default {
  createRoutes: (defaultLangCode, ...langCodes) => (...routes) => {
    LanguageRouterState.defaultLang = defaultLangCode
    LanguageRouterState.list = [defaultLangCode, ...langCodes]

    LanguageRouterState.routes = [
      ...langCodes.map(langRoutesFactory(routes)),
      langRoutesFactory(routes, true)(defaultLangCode)
    ]

    return LanguageRouterState.routes
  },
  install: Vue => {
    const { defaultLang = null, list = [] } = LanguageRouterState

    LanguageRouterState = new Vue({
      name: 'LangRouterVM',
      data() {
        return { defaultLang, list }
      }
    })

    const getLangRouteName = (context, routeName, lang) => {
      const langCode = lang || context.$lang.current
      const langCodeSuffix = langCode === LanguageRouterState.defaultLang ? '' : `:${langCode}`
      return `${routeName.replace(/:.+$/, '')}${langCodeSuffix}`
    }

    Vue.component('LangRouterLink', {
      name: 'LangRouterLink',
      props: {
        to: { type: Object, required: true },
        exact: { type: Boolean, default: false }
      },
      render(h) {
        const props = { to: this.$lang.route(this.to), exact: this.exact }
        return h('RouterLink', { props }, this.$slots.default)
      }
    })

    Vue.component('LangSwitchLink', {
      name: 'LangSwitchLink',
      props: {
        lang: { type: String, required: true }
      },
      render(h) {
        const props = {
          to: {
            params: this.$route.params,
            query: this.$route.query,
            name: getLangRouteName(this, this.$route.name, this.lang)
          }
        }

        return h('RouterLink', { props }, this.$slots.default)
      }
    })

    Vue.mixin({
      computed: {
        $lang() {
          return {
            list: LanguageRouterState.list,
            default: LanguageRouterState.defaultLang,
            routes: LanguageRouterState.routes,
            current: this.$store && this.$store.getters.currentLang,
            route: ({ name, ...route }) => ({ ...route, name: getLangRouteName(this, name) })
          }
        }
      }
    })
  }
}
