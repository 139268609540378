<template>
  <nav :class="['navbar', { '-mobile-open': isMobileNavOpen }]">
    <div class="navbar__content">
      <LangRouterLink :to="{ name: 'home' }" @click.native="isMobileNavOpen = false">
        <img src="/images/logo.svg" alt="SG NDT" class="navbar__logo" />
      </LangRouterLink>

      <nav class="navbar__links">
        <LangRouterLink v-for="{ label, ...link } in links" :key="label" class="navbar__item" :to="link">{{
          label
        }}</LangRouterLink>
      </nav>

      <menu :class="['navbar__contact', { '-home': isHome }]">
        <a :href="socials.facebook" target="_blank" rel="noopener" class="navbar__icon">
          <Icon id="facebook" />
        </a>

        <a :href="socials.linkedIn" target="_blank" rel="noopener" class="navbar__icon">
          <Icon id="linkedin" />
        </a>

        <a :href="socials.youtube" target="_blank" rel="noopener" class="navbar__icon">
          <Icon id="youtube" />
        </a>

        <LangRouterLink
          :to="{ name: 'contact' }"
          :class="['navbar__button', 'button', '-link', { '-primary': !isHome }]"
        >
          <template v-if="$device.laptop">{{ $lang.current === 'fr' ? 'Contactez-nous' : 'Contact Us' }}</template>
          <Icon v-else id="mail" />
        </LangRouterLink>

        <LangSwitchLink v-for="langCode in langSwitchLinks" :key="langCode" :lang="langCode" class="navbar__lang">{{
          langCode.toUpperCase()
        }}</LangSwitchLink>
      </menu>

      <menu class="row -mobile -align-center">
        <LangRouterLink :to="{ name: 'contact' }" class="navbar__mail" @click.native="isMobileNavOpen = false">
          <Icon id="mail" />
        </LangRouterLink>

        <button :class="['navbar__burger', { '-close': isMobileNavOpen }]" @click="isMobileNavOpen = !isMobileNavOpen">
          <span class="navbar__burger-line" />
        </button>
      </menu>
    </div>

    <transition name="mobile-nav" mode="out-in">
      <MobileNav
        v-if="!$device.laptop && isMobileNavOpen"
        :links="mobileLinks"
        :socials="socials"
        :lang-switch-links="langSwitchLinks"
        @close="isMobileNavOpen = false"
      />
    </transition>
  </nav>
</template>

<script>
import MobileNav from './MobileNav.vue'

export default {
  name: 'NavBar',
  components: { MobileNav },
  contentKey: ['about', 'markets', 'services'],
  data() {
    return {
      isMobileNavOpen: false
    }
  },
  computed: {
    productPages() {
      return Object.values(this.$store.getters.productTypes).map(({ name, slug }) => {
        return {
          name: 'products',
          label: name,
          params: { productTypeSlug: slug }
        }
      })
    },
    links() {
      const { about, markets, services } = this.$wp

      return [
        ...this.productPages,
        { name: 'services', label: services.metaTitle },
        { name: 'markets', label: markets.metaTitle },
        { name: 'about', label: about.metaTitle }
      ]
    },
    mobileLinks() {
      const { about, markets, services } = this.$wp

      return [
        ...this.productPages,
        { name: 'services', label: services.metaTitle },
        { name: 'markets', label: markets.metaTitle },
        { name: 'about', label: about.metaTitle },
        { name: 'contact', label: 'Contact' }
      ]
    },
    socials() {
      const [firstDivision] = this.$store.state.content.settings.divisions
      const [defaultOffice] = firstDivision.offices
      return defaultOffice.socials
    },
    langSwitchLinks() {
      return this.$lang.list.filter(lang => lang !== this.$lang.current)
    },
    isHome() {
      return this.$route.name.includes('home')
    }
  },
  watch: {
    isMobileNavOpen(isOpen, wasOpen) {
      if (isOpen === wasOpen) return
      if (isOpen) this.$scroll.prevent()
      else this.$scroll.restore()
    }
  }
}
</script>
