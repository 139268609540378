export default {
  'set/readyState': (state, isReady) => {
    state.isReady = isReady
  },
  'update/pageMap': (state, newPageMap) => {
    const { pageMap } = state
    state.pageMap = { ...pageMap, ...newPageMap }
  },
  'update/slugMap': (state, [mapKey, newMap]) => {
    state[mapKey] = { ...state[mapKey], ...newMap }
  },
  'update/settings': (state, newSettings) => {
    const { settings } = state.content
    state.content.settings = { ...settings, ...newSettings }
  },
  'update/content': (state, newContent) => {
    const { content } = state
    state.content = { ...content, ...newContent }
  },
  'update/productTypes': (state, newProductTypes) => {
    const { productTypes } = state
    state.productTypes = { ...productTypes, ...newProductTypes }
  },
  'replace/products': (state, newProducts) => {
    state.products = newProducts
  },
  'replace/services': (state, newServices) => {
    state.services = newServices
  },
  'replace/markets': (state, newMarkets) => {
    state.markets = newMarkets
  },
  'replace/values': (state, newValues) => {
    state.values = newValues
  }
}
