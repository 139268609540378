<template>
  <div class="page-contact">
    <header v-if="!$device.laptop" class="header -flat">
      <div class="header_content">
        <h1 v-scroll:reveal="{ delay: 550 }" class="header__legend">{{ $wp.contact.header.title }}</h1>
        <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $wp.contact.header.legend }}</p>
        <p v-scroll:reveal="{ delay: 650 }" class="header__intro">{{ $wp.contact.header.intro }}</p>
      </div>
    </header>
    <header ref="header" class="header -form">
      <div class="header__content">
        <figure class="header__background" />

        <div class="header__column">
          <template v-if="$device.laptop">
            <h1 v-scroll:reveal="{ delay: 550 }" class="header__legend">{{ $wp.contact.header.title }}</h1>
            <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $wp.contact.header.legend }}</p>
            <p v-scroll:reveal="{ delay: 650 }" class="header__intro">{{ $wp.contact.header.intro }}</p>
          </template>

          <ul v-if="$device.laptop" class="header__list">
            <li class="header__item">
              <h2 v-scroll:reveal="{ delay: 700, offset: 0 }" class="header__subtitle">
                {{ $wp.settings.divisions[0].name }}
              </h2>
              <p v-scroll:reveal="{ delay: 750, offset: 0 }" class="header__label">{{ defaultOffice.name }}.</p>

              <address v-scroll:reveal="{ delay: 800, offset: 0 }">
                {{ defaultOffice.address.lign1 }}
                <br />
                {{ defaultOffice.address.lign2 }}
                <br />
                {{ defaultOffice.address.country }}
              </address>

              <p v-scroll:reveal="{ delay: 850, offset: 0 }">{{ defaultOffice.phone }}</p>
              <p v-scroll:reveal="{ delay: 900, offset: 0 }">{{ defaultOffice.email }}</p>
            </li>

            <li class="header__item">
              <h2 v-scroll:reveal="{ delay: 800, offset: 0 }" class="header__subtitle">{{ otherDivision[0].name }}</h2>
              <p v-scroll:reveal="{ delay: 850, offset: 0 }" class="header__label">
                {{ otherDivision[0].offices[0].name }}.
              </p>

              <address v-if="otherDivision[0].offices[0].address.lign1" v-scroll:reveal="{ delay: 900 }">
                {{ otherDivision[0].offices[0].address.lign1 }}
                <br />
                {{ otherDivision[0].offices[0].address.lign2 }}
                <br />
                {{ otherDivision[0].offices[0].address.country }}
              </address>

              <p v-scroll:reveal="{ delay: 950, offset: 0 }">{{ otherDivision[0].offices[0].phone }}</p>
              <p v-scroll:reveal="{ delay: 1000, offset: 0 }">{{ otherDivision[0].offices[0].email }}</p>
            </li>
          </ul>
        </div>
        <transition name="fade" mode="out-in">
          <form v-if="isFormVisible" class="form" @submit.prevent="onSubmit">
            <div class="form__row">
              <InputText
                v-model="model.name"
                v-scroll:reveal="{ delay: 800, offset: 0 }"
                name="name"
                :label="$wp.contact.form.name.label"
                :input-class="['-contact']"
                :error="error.isNameInvalid"
                :error-message="errorMessage.name"
                class="form__field -half"
                @validate="validateName"
              />

              <InputText
                v-model="model.businessName"
                v-scroll:reveal="{ delay: 850, offset: 0 }"
                name="businessName"
                :label="$wp.contact.form.businessName.label"
                :input-class="['-contact']"
                :error="error.isBusinessNameInvalid"
                :error-message="errorMessage.businessName"
                class="form__field -half"
                @validate="validateBusinessName"
              />
            </div>

            <div class="form__row">
              <InputText
                v-model="model.email"
                v-scroll:reveal="{ delay: 900, offset: 0 }"
                name="name"
                :label="$wp.contact.form.email.label"
                :input-class="['-contact']"
                :error="error.isEmailInvalid"
                :error-message="errorMessage.email"
                class="form__field -full-width"
                @validate="validateEmail"
              />
            </div>

            <div class="form__row">
              <InputText
                v-model="model.message"
                v-scroll:reveal="{ delay: $device.laptop ? 950 : 0, offset: 0 }"
                name="message"
                :label="$wp.contact.form.message.label"
                :input-class="['-contact']"
                :error="error.isMessageInvalid"
                :error-message="errorMessage.message"
                class="form__field -full-width"
                textarea
                @validate="validateMessage"
              />
            </div>

            <div v-scroll:reveal="{ delay: $device.laptop ? 1000 : 0, offset: 0 }" class="form__row -button">
              <button class="button" type="submit" @click="onSubmit">{{ $wp.contact.form.buttonLabel }}</button>
            </div>
          </form>
          <div v-else-if="isConfirmCardVisible" class="confirmation-card">
            <h2 v-scroll:reveal="{ delay: 250 }" class="confirmation-card__subtitle">
              {{ $wp.contact.form.confirmationSubtitle }}
            </h2>
            <p v-scroll:reveal="{ delay: 300 }" class="sconfirmation-card__text">
              {{ $wp.contact.form.confirmationText }}
            </p>
          </div>
        </transition>
      </div>
    </header>

    <section v-if="!$device.laptop" class="section">
      <ul class="section__content">
        <li class="header__item">
          <h2 v-scroll:reveal="{ delay: 700 }" class="header__subtitle">{{ $wp.settings.divisions[0].name }}</h2>
          <p v-scroll:reveal="{ delay: 750 }" class="header__label">{{ defaultOffice.name }}.</p>

          <address v-scroll:reveal="{ delay: 800 }">
            {{ defaultOffice.address.lign1 }}
            <br />
            {{ defaultOffice.address.lign2 }}
            <br />
            {{ defaultOffice.address.country }}
          </address>

          <p v-scroll:reveal="{ delay: 850 }">{{ defaultOffice.phone }}</p>
          <p v-scroll:reveal="{ delay: 900 }">{{ defaultOffice.email }}</p>
        </li>

        <li class="header__item">
          <h2 v-scroll:reveal="{ delay: 800 }" class="header__subtitle">{{ otherDivision[0].name }}</h2>
          <p v-scroll:reveal="{ delay: 850 }" class="header__label">{{ otherDivision[0].offices[0].name }}.</p>

          <address v-if="otherDivision[0].offices[0].address.lign1" v-scroll:reveal="{ delay: 900 }">
            {{ otherDivision[0].offices[0].address.lign1 }}
            <br />
            {{ otherDivision[0].offices[0].address.lign2 }}
            <br />
            {{ otherDivision[0].offices[0].address.country }}
          </address>

          <p v-scroll:reveal="{ delay: 950 }">{{ otherDivision[0].offices[0].phone }}</p>
          <p v-scroll:reveal="{ delay: 1000 }">{{ otherDivision[0].offices[0].email }}</p>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { PageMetaMixin } from '@/mixins'
import { InputText } from '@/components/inputs'

export default {
  name: 'Contact',
  contentKey: ['contact', 'settings'],
  components: { InputText },
  mixins: [PageMetaMixin],
  data() {
    return {
      model: {
        name: '',
        businessName: '',
        email: '',
        message: ''
      },
      error: {
        isNameInvalid: false,
        isBusinessNameInvalid: false,
        isEmailInvalid: false,
        isMessageInvalid: false
      },
      errorMessage: {
        name: '',
        businessName: '',
        email: '',
        message: ''
      },
      regex: {
        name: /^[a-zA-ZÀ-ÿ\- ]{1,50} [a-zA-ZÀ-ÿ\- ]{1,50}$/,
        businessName: /^[0-9a-zA-ZÀ-ÿ\- ]{1,200}$/,
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: /^[0-9a-zA-ZÀ-ÿ\- ]{1,500}$/
      },
      isFormVisible: true,
      isConfirmCardVisible: false
    }
  },
  computed: {
    defaultDivision() {
      const [firstDivision] = this.$store.state.content.settings.divisions
      return firstDivision
    },
    defaultOffice() {
      const [defaultOffice] = this.defaultDivision.offices
      return defaultOffice
    },
    otherDivision() {
      return this.$store.state.content.settings.divisions.slice(1)
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.showRecaptcha()
      }
    }
  },
  methods: {
    showRecaptcha() {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    onSubmit() {
      this.validateName()
      this.validateBusinessName()
      this.validateEmail()
      this.validateMessage()

      const isFormValid = this.validateForm()

      if (isFormValid) {
        const { name, businessName, email, message } = this.model

        this.showConfirmCard()
        this.resetForm()
        this.$recaptcha('contact').then(() => {
          axios({
            method: 'post',
            baseURL: process.env.VUE_APP_API_URL,
            url: '?rest_route=/beet/v1/contact',
            data: { name, businessName, email, message }
          }).then(success => {
            if (!success) return console.error('An error occurred sending the email')
          })
        })

      }
    },
    showConfirmCard() {
      this.isFormVisible = false
      this.isConfirmCardVisible = true
    },
    validateForm() {
      let isFormValid = true
      Object.values(this.error).forEach(error => {
        if (error) isFormValid = false
      })
      return isFormValid
    },
    resetForm() {
      this.model.name = ''
      this.model.businessName = ''
      this.model.email = ''
      this.model.message = ''
    },
    validateName() {
      if (!this.model.name) {
        this.error.isNameInvalid = true
        this.errorMessage.name = this.$wp.contact.form.name.errorEmpty
      } else if (!this.regex.name.test(this.model.name)) {
        this.error.isNameInvalid = true
        this.errorMessage.name = this.$wp.contact.form.name.errorPattern
      } else {
        this.error.isNameInvalid = false
      }
    },
    validateBusinessName() {
      if (!this.model.businessName) {
        this.error.isBusinessNameInvalid = true
        this.errorMessage.businessName = this.$wp.contact.form.businessName.errorEmpty
      } else if (!this.regex.businessName.test(this.model.businessName)) {
        this.error.isBusinessNameInvalid = true
        this.errorMessage.businessName = this.$wp.contact.form.businessName.errorPattern
      } else {
        this.error.isBusinessNameInvalid = false
      }
    },
    validateEmail() {
      if (!this.model.email) {
        this.error.isEmailInvalid = true
        this.errorMessage.email = this.$wp.contact.form.email.errorEmpty
      } else if (!this.regex.email.test(this.model.email.toLowerCase())) {
        this.error.isEmailInvalid = true
        this.errorMessage.email = this.$wp.contact.form.email.errorPattern
      } else {
        this.error.isEmailInvalid = false
      }
    },
    validateMessage() {
      if (!this.model.message) {
        this.error.isMessageInvalid = true
        this.errorMessage.message = this.$wp.contact.form.message.errorEmpty
      } else if (!this.regex.message.test(this.model.message)) {
        this.error.isEmailInvalid = true
        this.errorMessage.message = this.$wp.contact.form.message.errorPattern
      } else {
        this.error.isMessageInvalid = false
      }
    }
  }
}
</script>
