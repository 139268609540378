<template>
  <div class="media-slider">
    <transition :name="imageTransitionName" mode="out-in" appear>
      <figure
        :key="currentIndex"
        v-touch:swipe.left="next"
        v-touch:swipe.right="prev"
        class="media-slider__image"
        :style="background"
      />
    </transition>
    <menu class="media-slider__nav">
      <button
        v-for="(n, index) in slides.length"
        :key="n"
        :class="['media-slider__dot', { '-active': currentIndex === index }]"
        @click="goToIndex(index)"
      ></button>
    </menu>

    <menu class="media-slider__menu">
      <button class="media-slider__button" @click="prev()">
        <Icon id="back" class="icon-back" />
      </button>

      <button class="media-slider__button" @click="next()">
        <Icon id="next" class="icon-next" />
      </button>
    </menu>
  </div>
</template>

<script>
export default {
  name: 'MediaSlider',
  props: {
    slides: { type: Array, default: Array }
  },
  data() {
    return {
      currentIndex: 0,
      isPrev: false
    }
  },
  computed: {
    currentSlide() {
      return this.slides[this.currentIndex] || {}
    },
    background() {
      return { backgroundImage: `url('${this.currentSlide.image}')` }
    },
    imageTransitionName() {
      return !this.isPrev ? 'media-slider__image-next' : 'media-slider__image-prev'
    }
  },
  methods: {
    next() {
      this.isPrev = false
      this.currentIndex = (this.currentIndex + 1) % this.slides.length
    },
    prev() {
      this.isPrev = true
      const wrappedIndex = this.currentIndex || this.slides.length
      this.currentIndex = wrappedIndex - 1
    },
    goToIndex(index) {
      this.isPrev = index < this.currentIndex
      this.currentIndex = index
    }
  }
}
</script>
