<template>
  <div class="page-home">
    <header class="header -home">
      <div class="header__content">
        <figure class="header__background" />

        <div class="header__column">
          <h2 v-scroll:reveal="{ delay: 550 }" class="header__legend">{{ $wp.header.title }}</h2>
          <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $wp.header.legend }}</p>
          <p v-scroll:reveal="{ delay: 650 }" class="header__intro">{{ $wp.header.intro }}</p>

          <nav class="header__nav">
            <LangRouterLink
              v-scroll:reveal="{ delay: 700, offset: 0 }"
              :to="{ name: 'products', params: { productTypeSlug: 'produits-oem' } }"
              class="button -link -primary header__link"
              >{{ $wp.header.productLinkLabel }}</LangRouterLink
            >

            <LangRouterLink
              v-scroll:reveal="{ delay: 750, offset: 0 }"
              :to="{ name: 'services', hash: '#oem' }"
              class="button -link header__link"
              >{{ $wp.header.servicesLinkLabel }}</LangRouterLink
            >
          </nav>
        </div>

        <HeroSlider :slides="$wp.header.items" />
      </div>
    </header>

    <section class="section row">
      <div class="row__item -span-4">
        <h2 v-scroll:reveal class="section__title">{{ $wp.services.title }}</h2>
        <LangRouterLink v-scroll:reveal="{ delay: 75 }" :to="{ name: 'services' }" class="button -link">{{
          $wp.services.linkLabel
        }}</LangRouterLink>
      </div>

      <div class="section__content row__item grid-list -full-width">
        <article
          v-for="(item, i) in services"
          :key="item.id"
          v-scroll:reveal="{ delay: 150 + i * 50 }"
          class="grid-list__item row -align-start -mobile"
        >
          <figure class="disc -inline" :style="{ backgroundImage: `url('${item.medias.badge}')` }" />

          <div class="row__item">
            <h3 class="card__title">{{ item.title }}</h3>
            <p>{{ item.excerpt }}</p>

            <LangRouterLink
              :to="{ name: 'services', hash: `#${item.slug}` }"
              class="section__link"
              :aria-labelledby="'En apprendre plus sur ' + item.title"
              >{{ item.linkLabel }}</LangRouterLink
            >
          </div>
        </article>
      </div>
    </section>

    <section class="section">
      <h2 class="section__title">{{ $wp.products.title }}</h2>

      <div class="section__content grid-list -full-width">
        <ProductCard
          v-for="(item, i) in productTypes"
          :key="item.id"
          v-scroll:reveal="{ delay: i * 50 }"
          :image-url="item.medias.image"
          class="grid-list__item"
        >
          <h3 class="card__title">{{ item.name }}</h3>
          <p>{{ item.excerpt }}</p>

          <LangRouterLink
            :to="{ name: 'products', params: { productTypeSlug: item.slug } }"
            :aria-labelledby="'Apprenez-en plus sur' + item.name"
            class="section__link"
            >{{ item.linkLabel }}</LangRouterLink
          >
        </ProductCard>
      </div>
    </section>

    <section class="section -center">
      <h2 v-scroll:reveal class="section__title">{{ $wp.values.title }}</h2>
      <p v-scroll:reveal="{ delay: 75 }" class="section__subtitle">{{ $wp.values.subtitle }}</p>

      <ul class="icon-list">
        <li
          v-for="(item, key, i) in values"
          :key="item.id"
          v-scroll:reveal="{ delay: 150 + 50 * i }"
          class="icon-list__item"
        >
          <figure class="disc -center" :style="{ backgroundImage: `url('${item.media.badge}')` }" />
          <p class="icon-list__label">{{ item.title }}</p>
        </li>
      </ul>

      <LangRouterLink v-scroll:reveal="{ delay: 250 }" :to="{ name: 'about' }" class="button -link">{{
        $wp.values.linkLabel
      }}</LangRouterLink>
    </section>

    <section class="section row">
      <div class="row__item -span-4">
        <h2 v-scroll:reveal class="section__title">{{ $wp.markets.title }}</h2>
        <LangRouterLink v-scroll:reveal="{ delay: 75 }" :to="{ name: 'markets' }" class="button -link">{{
          $wp.markets.linkLabel
        }}</LangRouterLink>
      </div>

      <ul :class="['section__content', 'row__item', '-span-8', 'grid-list', { '-full-width': !$device.laptop }]">
        <Card
          v-for="(item, i) in markets"
          :key="item.id"
          v-scroll:reveal="{ delay: 150 + 50 * i }"
          :image-url="item.medias.image"
          :badge-url="item.medias.badge"
          class="grid-list__item"
        >
          <h3 class="card__title">{{ item.title }}</h3>
          <p>{{ item.excerpt }}</p>

          <LangRouterLink
            :to="{ name: 'markets', hash: `#${item.slug}` }"
            class="section__link"
            :aria-labelledby="'En apprendre plus sur ' + item.title"
            >{{ item.linkLabel }}</LangRouterLink
          >
        </Card>
      </ul>
    </section>
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { Card, ProductCard } from '@/components/partials'
import { HeroSlider } from '@/components/sliders'

export default {
  name: 'Home',
  contentKey: 'home',
  components: { HeroSlider, Card, ProductCard },
  mixins: [PageMetaMixin],
  computed: {
    services() {
      return this.$wp.services.items.map(serviceId => {
        return this.$store.state.services[serviceId]
      })
    },
    markets() {
      return this.$wp.markets.items.map(marketId => {
        return this.$store.state.markets[marketId]
      })
    },
    values() {
      return this.$store.state.values
    },
    productTypes() {
      return this.$store.getters.productTypes
    }
  }
}
</script>
