<template>
  <div class="product-card">
    <figure class="product-card__image" :style="{ backgroundImage: `url('${imageUrl}')` }" />

    <div v-if="badgeUrl" class="disc">
      <img :src="badgeUrl" class="disc__icon" />
    </div>

    <div class="product-card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: {
    badgeUrl: { type: [String, Boolean], default: false },
    imageUrl: { type: [String, Boolean], default: false }
  }
}
</script>
