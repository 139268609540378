<template>
  <section class="section">
    <h1 class="section__title">{{ currentContent.title }}</h1>
    <RawHtml :html="currentContent.text" />
  </section>
</template>

<script>
export default {
  name: 'LegalText',
  contentKey: 'terms, privacy',
  computed: {
    currentContent() {
      const prefix = this.$route.name.replace(/:\w\w$/, '')
      return {
        title: this.$store.state.content[`${prefix}`].title,
        text: this.$store.state.content[`${prefix}`].richText
      }
    }
  }
}
</script>
