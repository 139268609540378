<template>
  <div :id="hash" :class="['card', { '-split': split }]">
    <figure
      :class="['card__image', { '-small': smallImage }, { '-large': largeImage }]"
      :style="{ backgroundImage: `url('${imageUrl}')` }"
    />

    <div v-if="badgeUrl" class="card__badge">
      <img :src="badgeUrl" class="card__badge-icon" />
    </div>

    <div class="card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    badgeUrl: { type: [String, Boolean], default: false },
    imageUrl: { type: [String, Boolean], default: false },
    split: { type: Boolean, default: false },
    smallImage: { type: Boolean, default: false },
    largeImage: { type: Boolean, default: false },
    hash: { type: String, default: null }
  }
}
</script>
