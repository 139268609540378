<template>
  <main v-if="$store.state.isReady" id="app" class="layout">
    <NavBar />

    <Transition name="page" mode="out-in" appear @after-leave="setScrollPosition" @enter="updateScrollPosition">
      <RouterView :key="$route.name" class="page layout__view" />
    </Transition>
    <CookieBar />
    <SiteFooter />
  </main>
</template>

<script>
import { NavBar, SiteFooter, CookieBar } from './components'

export default {
  name: 'App',
  metaInfo: {
    title: 'SG NDT',
    titleTemplate: '%s | SG NDT'
  },
  components: { NavBar, SiteFooter, CookieBar },
  data() {
    return {
      scrollPosition: 0,
      isMobileNavOpen: false
    }
  },
  watch: {
    $route({ name, hash }, from) {
      if (name !== from.name || !hash) return
      const delay = this.$device.tablet ? 0 : 150
      return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), delay)
    },
    '$lang.current': {
      immediate: true,
      handler(lang, prevLang) {
        if (lang === prevLang) return
        this.fetchContent(lang)
      }
    }
  },
  methods: {
    fetchContent(lang) {
      this.isContentLoading = true

      this.$store.dispatch('fetchContent', lang).then(() => {
        this.isContentLoading = false
        this.isContentReady = true
      })
    },
    updateScrollPosition() {
      this.$nextTick(() => {
        const { hash } = this.$route
        if (hash) return setTimeout(() => this.$scroll.toTarget(hash, 80, 350), 150)
        this.$scroll.to(this.scrollPosition)
      })
    },
    setScrollPosition() {
      this.scrollPosition = window.savedScroll || 0
      this.$scroll.to(0)
    }
  }
}
</script>
