import Element from './Element'
import Icon from './Icon.vue'
import DropDown from './DropDown.vue'
import PageLink from './PageLink.vue'
import RawHtml from './RawHtml.vue'

const globals = [Element, Icon, DropDown, PageLink, RawHtml]

export { Element, Icon, DropDown, PageLink, RawHtml }

export default Vue => globals.forEach(component => Vue.component(component.name, component))
