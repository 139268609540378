<template>
  <div class="page-markets">
    <header class="header">
      <div class="header__content">
        <figure class="header__background -markets">
          <img v-if="$device.laptop" :src="$wp.header.product.sliderImage" class="header__background-image -markets" />
        </figure>
        <LangRouterLink
          v-if="$device.laptop"
          :to="{
            name: 'product',
            params: { productTypeSlug: productParams.productTypeSlug, productSlug: productParams.productSlug }
          }"
          class="hero-slider__content -markets"
        >
          <figure class="hero-slider__thumb">
            <img :src="$wp.header.product.slideThumb" :alt="$wp.header.product.slideTitle" />
          </figure>

          <div class="hero-slider__text">
            <p class="hero-slider__title">{{ $wp.header.product.slideTitle }}</p>
            <p>{{ $wp.header.product.slideText }}</p>
          </div>
        </LangRouterLink>
        <LangRouterLink
          v-else
          :to="{
            name: 'product',
            params: { productTypeSlug: productParams.productTypeSlug, productSlug: productParams.productSlug }
          }"
          class="hero-slider__show"
        >
          <Icon id="show" class="icon-show" />
        </LangRouterLink>
        <img v-if="!$device.laptop" :src="$wp.header.product.sliderImage" class="header__background-image -markets" />
        <div class="header__column">
          <h1 v-scroll:reveal="{ delay: 550 }" class="header__legend">{{ $wp.header.title }}</h1>
          <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $wp.header.legend }}</p>
          <p v-scroll:reveal="{ delay: 650 }" class="header__intro">{{ $wp.header.intro }}</p>

          <nav class="header__nav -compact">
            <LangRouterLink
              v-for="(item, i) in anchors"
              :key="item.id"
              v-scroll:reveal="{ delay: 700 + i * 50, offset: -100 }"
              :to="{ name: 'markets', hash: item.hash }"
              class="header__anchor"
            >
              {{ item.label }}
            </LangRouterLink>
          </nav>
        </div>
      </div>
    </header>

    <section class="section">
      <div class="section__content -pad">
        <Card
          v-scroll:reveal
          :badge-url="markets.top.medias.badge"
          :image-url="markets.top.medias.image"
          :hash="markets.top.slug"
          split
          large-image
        >
          <h2 class="section__title">{{ markets.top.title }}</h2>
          <RawHtml :html="markets.top.richText" />
        </Card>
      </div>
    </section>

    <section class="section">
      <div :class="['section__content', '-push', 'grid-list', { '-full-width': !$device.laptop }]">
        <Card
          v-for="(item, i) in markets.row"
          :key="item.id"
          v-scroll:reveal="{ delay: i * 75 }"
          :hash="item.slug"
          :badge-url="item.medias.badge"
          :image-url="item.medias.image"
          class="grid-list__item"
          small-image
        >
          <h2 class="section__title">{{ item.title }}</h2>
          <RawHtml :html="item.richText" />
        </Card>
      </div>
    </section>

    <section v-if="markets.rest.length" class="section">
      <div class="section__content -pad -push -column">
        <Card
          v-for="(item, i) in markets.rest"
          :key="item.id"
          v-scroll:reveal="{ delay: i * 75 }"
          :hash="item.slug"
          :badge-url="item.medias.badge"
          :image-url="item.medias.image"
          split
          large-image
        >
          <h2 class="section__title">{{ item.title }}</h2>
          <RawHtml :html="item.richText" />
        </Card>
      </div>
    </section>

    <CtaContact :title="$wp.contact.title" :subtitle="$wp.contact.subtitle" :link-label="$wp.contact.linkLabel" />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { Card, CtaContact } from '@/components/partials'

export default {
  name: 'Markets',
  contentKey: 'markets',
  components: { Card, CtaContact },
  mixins: [PageMetaMixin],
  computed: {
    markets() {
      const markets = Object.values(this.$store.state.markets)
      const [top, second, third, ...rest] = markets
      return { top, row: [second, third], rest }
    },
    anchors() {
      return this.$store.getters.anchors('markets')
    },
    productParams() {
      const [productId] = this.$wp.header.product.slideProduct
      const { slug: productSlug, typeId } = this.$store.state.products[productId]
      const { slug: productTypeSlug } = this.$store.getters.productTypes[typeId]
      return { productSlug, productTypeSlug }
    }
  }
}
</script>
