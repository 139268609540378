import axios from 'axios'

const ignoreProductType = /uncategorized|non-classifiee/

const { VUE_APP_API_URL } = process.env
const apiUrl = VUE_APP_API_URL.replace(/\/$/, '')

const getQueryString = query => {
  const queryString = Object.entries(query)
    .map(pair => pair.join('='))
    .join('&')

  return !queryString.length ? '' : `?${queryString}`
}

export default {
  fetchContent: ({ commit }, lang) => {
    const langQuery = !lang || lang === 'fr' ? {} : { lang }

    const asyncContentRequests = [
      ['/beet/v1/site-data'],
      ['/acf/v3/options/options'],
      ['/acf/v3/pages', { per_page: 100 }],
      ['/acf/v3/product', { per_page: 100 }],
      ['/acf/v3/service', { per_page: 100 }],
      ['/acf/v3/market', { per_page: 100 }],
      ['/acf/v3/value', { per_page: 100 }],
      ['/wp/v2/product-type', { per_page: 100 }]
    ].map(([route, query = {}]) =>
      axios(`${apiUrl}/${getQueryString({ ...langQuery, ...query, rest_route: route })}`).then(({ data }) => data)
    )

    return Promise.all(asyncContentRequests).then(
      ([
        { pageMap, productMap, serviceMap, marketMap, valueMap },
        { acf: settings },
        pages,
        products,
        services,
        markets,
        values,
        productType
      ]) => {
        const productTypes = productType.reduce((cats, { id, slug, name, acf }) => {
          return ignoreProductType.test(slug) ? cats : { ...cats, [id]: { id, name, slug, ...acf } }
        }, {})

        const { general } = settings

        commit('update/pageMap', pageMap)
        commit('update/slugMap', ['productMap', productMap])
        commit('update/settings', general)
        commit('update/productTypes', productTypes)

        commit(
          'update/content',
          pages.reduce((content, { id, acf }) => {
            const { slug, title: metaTitle } = pageMap[id] || {}
            return !slug ? content : { ...content, [slug]: { metaTitle, ...acf } }
          }, {})
        )

        commit(
          'replace/products',
          products.reduce((content, { id, acf }) => {
            const { slug, title } = productMap[id] || {}
            return !slug ? content : { ...content, [id]: { id, slug, title, metaTitle: title, ...acf } }
          }, {})
        )

        commit(
          'replace/services',
          services.reduce((content, { id, acf }) => {
            const { slug, title } = serviceMap[id] || {}
            return !slug ? content : { ...content, [id]: { id, slug, title, metaTitle: title, ...acf } }
          }, {})
        )

        commit(
          'replace/markets',
          markets.reduce((content, { id, acf }) => {
            const { slug, title } = marketMap[id] || {}
            return !slug ? content : { ...content, [id]: { id, slug, title, metaTitle: title, ...acf } }
          }, {})
        )

        commit(
          'replace/values',
          values.reduce((content, { id, acf }) => {
            const { slug, title } = valueMap[id] || {}
            return !slug ? content : { ...content, [id]: { id, slug, title, metaTitle: title, ...acf } }
          }, {})
        )
        commit('set/readyState', true)
      }
    )
  }
}
