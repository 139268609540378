<template>
  <div class="page-about">
    <header class="header">
      <div class="header__content">
        <figure class="header__image" :style="{ backgroundImage: `url('${$wp.header.image}')` }" />

        <div class="header__column">
          <h1 v-scroll:reveal="{ delay: 550 }" class="header__legend">{{ $wp.header.title }}</h1>
          <p v-scroll:reveal="{ delay: 600 }" class="header__title">{{ $wp.header.legend }}</p>
          <p v-scroll:reveal="{ delay: 650 }" class="header__intro">{{ headerIntro }}</p>

          <nav class="header__nav -compact">
            <LangRouterLink
              v-for="(item, i) in anchors"
              :key="item.id"
              v-scroll:reveal="{ delay: 700 + i * 50, offset: -100 }"
              :to="{ name: 'about', hash: item.hash }"
              class="header__anchor"
            >
              {{ item.label }}
            </LangRouterLink>
          </nav>
        </div>
      </div>
    </header>

    <section id="business" class="section">
      <h2 v-scroll:reveal class="section__legend">{{ $wp.enterprise.title }}</h2>
      <h3 v-scroll:reveal="{ delay: 75 }" class="section__title">{{ $wp.enterprise.legend }}</h3>

      <div class="section__content row">
        <div v-scroll:reveal="{ delay: 100 }" class="section__column">
          <p>{{ $wp.enterprise.intro }}</p>
        </div>

        <div class="section__column">
          <h3 v-scroll:reveal="{ delay: 150 }" class="section__subtitle">{{ $wp.enterprise.team.subtitle }}</h3>
          <p v-scroll:reveal="{ delay: 200 }">{{ $wp.enterprise.team.text }}</p>
        </div>
      </div>
    </section>

    <section class="section">
      <h3 v-scroll:reveal="{ delay: 75 }" class="section__title">{{ managementTitle }}</h3>

      <div v-for="(row, idx) in team" :key="idx" class="section__content -push row">
        <div v-for="({ name, description }, i) in row" :key="name" class="section__column">
          <h3 v-scroll:reveal="{ delay: i * 75 }" class="section__subtitle">{{ name }}</h3>
          <p v-scroll:reveal="{ delay: i * 75 + 75 }">{{ description }}</p>
        </div>
      </div>

      <div v-scroll:reveal class="section__content -background row">
        <div class="section__column">
          <h3 v-scroll:reveal="{ delay: 75 }" class="section__subtitle">{{ $wp.enterprise.mission.subtitle }}</h3>
          <p v-scroll:reveal="{ delay: 125 }">{{ $wp.enterprise.mission.text }}</p>
        </div>

        <div class="section__column">
          <h3 v-scroll:reveal="{ delay: 150 }" class="section__subtitle">{{ $wp.enterprise.vision.subtitle }}</h3>
          <p v-scroll:reveal="{ delay: 200 }">{{ $wp.enterprise.vision.text }}</p>
        </div>
      </div>
    </section>

    <section class="section -center">
      <h3 v-scroll:reveal class="section__subtitle">{{ $wp.enterprise.values.subtitle }}</h3>

      <ul class="icon-list">
        <li
          v-for="(item, key, i) in values"
          :key="item.id"
          v-scroll:reveal="{ delay: 150 + 50 * i }"
          class="icon-list__item"
        >
          <figure class="disc -center" :style="{ backgroundImage: `url('${item.media.badge}')` }" />
          <p class="icon-list__label">{{ item.title }}</p>
        </li>
      </ul>
    </section>

    <TestimonialSlider
      v-bind="$wp.clients.items[currentTestimonialIndex]"
      :title="$wp.clients.title"
      :legend="$wp.clients.legend"
      :current-index="currentTestimonialIndex"
      :slides-count="$wp.clients.items.length"
      @index-change="handleTestimonialIndexChange"
    />

    <section id="partnerships" class="section -center">
      <h2 v-scroll:reveal class="section__legend">{{ $wp.partnerships.title }}</h2>
      <h3 v-scroll:reveal="{ delay: 75 }" class="section__title">{{ $wp.partnerships.legend }}</h3>
      <p v-scroll:reveal="{ delay: 150 }" class="section__intro">{{ $wp.partnerships.intro }}</p>
      <img v-scroll:reveal="{ delay: 250 }" src="/images/map.svg" class="map" />
    </section>

    <section id="careers" class="section">
      <div class="section__content -pad">
        <h2 v-scroll:reveal class="section__legend">{{ $wp.careers.title }}</h2>
        <h3 v-scroll:reveal="{ delay: 75 }" class="section__title">{{ $wp.careers.legend }}</h3>
        <RawHtml v-scroll:reveal="{ delay: 125 }" :html="$wp.careers.richText" />
      </div>
    </section>

    <CtaContact :title="$wp.contact.title" :subtitle="$wp.contact.subtitle" :link-label="$wp.contact.linkLabel" />
  </div>
</template>

<script>
import { PageMetaMixin } from '@/mixins'
import { CtaContact, TestimonialSlider } from '@/components'

export default {
  name: 'About',
  contentKey: 'about',
  components: { CtaContact, TestimonialSlider },
  mixins: [PageMetaMixin],
  data() {
    return {
      refsReady: false,
      currentTestimonialIndex: 0
    }
  },
  computed: {
    values() {
      return this.$store.state.values
    },
    anchors() {
      return this.$store.getters.anchors('about')
    },
    managementTitle() {
      return this.$lang.current === 'fr' ? 'Direction' : 'Management'
    },
    headerIntro() {
      const { intro } = this.$wp.header
      if (this.$device.laptop) return intro

      const trimmedIntro = intro.substring(0, 150)
      return `${trimmedIntro.split('.').slice(0, -1).join('.')}.`
    },
    team() {
      return this.$wp.team.reduce((teamRows, member, index) => {
        if (index % 2 === 0) return [...teamRows, [member]]
        const rows = [...teamRows]
        const last = rows.pop()
        return [...rows, [...last, member]]
      }, [])
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refsReady = true
    })
  },
  methods: {
    handleTestimonialIndexChange(index) {
      if (index >= this.$wp.clients.items.length || index < 0) return
      this.currentTestimonialIndex = index
    }
  }
}
</script>
