import Vue from 'vue'
import VueRouter from 'vue-router'

import { Home, About, Contact, LegalText, Markets, Services, Product, Products, NotFound } from '@/views'
import LangRouter from '@/extensions/LangRouter'

Vue.use(VueRouter)
Vue.use(LangRouter)

const routes = LangRouter.createRoutes('fr', 'en')(
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: { en: '/about', fr: '/a-propos' },
    name: 'about',
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: { en: '/markets', fr: '/marches' },
    name: 'markets',
    component: Markets
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: { en: '/products/:productTypeSlug?', fr: '/produits/:productTypeSlug?' },
    name: 'products',
    component: Products,
    props: true
  },
  {
    path: { en: '/products/:productTypeSlug/:productSlug', fr: '/produits/:productTypeSlug/:productSlug' },
    name: 'product',
    component: Product,
    props: true
  },
  {
    path: { en: 'terms', fr: 'conditions' },
    name: 'terms',
    component: LegalText
  },
  {
    path: { en: 'privacy', fr: 'confidentialite' },
    name: 'privacy',
    component: LegalText
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '*',
    name: 'catch-all',
    component: NotFound
  }
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '-active',
  routes
})

export default router
