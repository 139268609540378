<template>
  <div>
    <nav class="mobile-nav">
      <LangRouterLink
        v-for="{ label, ...link } in links"
        :key="label"
        class="mobile-nav__link"
        :to="link"
        @click.native="$emit('close')"
        >{{ label }}</LangRouterLink
      >
      <nav class="mobile-nav__bottom">
        <LangSwitchLink
          v-for="langCode in langSwitchLinks"
          :key="langCode"
          :lang="langCode"
          class="mobile-nav__lang"
          @click.native="$emit('close')"
        >
          {{ langCode.toUpperCase() }}
        </LangSwitchLink>

        <div>
          <a :href="socials.facebook" target="_blank" rel="noopener" class="navbar__icon">
            <Icon id="facebook" />
          </a>

          <a :href="socials.linkedIn" target="_blank" rel="noopener" class="navbar__icon">
            <Icon id="linkedin" />
          </a>

          <a :href="socials.youtube" target="_blank" rel="noopener" class="navbar__icon">
            <Icon id="youtube" />
          </a>
        </div>
      </nav>
      <nav class="mobile-nav__legal">
        <LangRouterLink
          v-for="({ label, route }, key) in $store.state.content.links.legal"
          :key="key"
          class="mobile-nav__legal__item"
          :to="route"
          @click.native="$emit('close')"
          >{{ label[$lang.current] }}</LangRouterLink
        >
      </nav>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'MobileNav',
  props: {
    links: { type: Array, default: Array },
    socials: { type: Object, default: null },
    langSwitchLinks: { type: Array, default: Array }
  }
}
</script>
