export default Vue => {
  const GlobalEventVM = new Vue({ name: 'GlobalEventsVM' })

  Vue.prototype.$globalEvents = {
    emit: (event, payload) => GlobalEventVM.$emit(event, payload),
    on: (event, handler) => GlobalEventVM.$on(event, handler),
    once: (event, handler) => GlobalEventVM.$once(event, handler),
    off: (event, handler) => GlobalEventVM.$off(event, handler)
  }
}
